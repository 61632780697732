/* eslint-disable */
import React, { Component } from 'react';
// import Buy from './Buy';
import Zone from './Zone';
import Detail from './Detail';
import EventAll from './EventAll';

type Props = {
  eventReducer: Object,
  loginReducer: Object,
  saveBooking: Function,
  goPageLogin: Function,
  getlistTalbleNotEmpty: Function
};

type State = { activeTab: string, activeEvent: Object };

export default class BuyTicketIndex extends Component<Props, State> {
  state = {
    activeTab: 'tabDetail',
    activeEvent: {}
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  toggleTab = tabId => {
    this.setState({
      activeTab: tabId
    });
  };

  toggleCheckActiveTab = tabId => {
    const { activeTab } = this.state;
    return tabId === activeTab
      ? 'tab-nav flex justify-content-center align-items-center active'
      : 'tab-nav flex justify-content-center align-items-center';
  };

  toggleClickEvent = event => {
    if (this.refs.forFocus) {
      this.refs.forFocus.focus();
    }

    this.setState({
      activeEvent: event,
      activeTab: 'tabDetail'
    });

    console.log('event', event);
    const { getlistTalbleNotEmpty } = this.props;
    getlistTalbleNotEmpty(event.eventId);

    document.getElementById('detail').focus();
  };

  render() {
    const { activeTab, activeEvent, focus } = this.state;
    const {
      eventReducer: { eventAll, listTableNotEmpty },
      loginReducer,
      saveBooking,
      goPageLogin,
      getlistTalbleNotEmpty
    } = this.props;

    if (!activeEvent.eventId && eventAll) {
      this.setState({
        activeEvent: eventAll[0]
      });
    }
    return (
      <div className="tickets-page">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-header">
                  <h2 className="entry-title">จองคอนเสิร์ต</h2>
                  <ul className="breadcrumbs flex align-items-center">
                    <li>
                      <a href="#">หน้าแรก</a>
                    </li>
                    <li>จองคอนเสิร์ต</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="container">
            <div className="entry-header">
              <div className="entry-title">
                <EventAll
                  eventAll={eventAll}
                  clickEvent={this.toggleClickEvent}
                />
              </div>
            </div>
            <br />
            <br />
            <br />

            <div className="entry-header">
              <div className="entry-title">
                <p>JUST THE BEST</p>
                <h2>จองคอนเสิร์ต</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tabs">
                  <ul className="tabs-nav flex">
                    <div
                      className={this.toggleCheckActiveTab('tabDetail')}
                      onClick={() => this.toggleTab('tabDetail')}
                    >
                      รายละเอียด
                    </div>
                    <div
                      className={this.toggleCheckActiveTab('tabZone')}
                      onClick={() => this.toggleTab('tabZone')}
                    >
                      จองโต๊ะ
                    </div>
                    {/* <div
                      className={this.toggleCheckActiveTab('tabBuyTicket')}
                      onClick={() => this.toggleTab('tabBuyTicket')}
                    >
                      Buy Ticket
                    </div> */}
                    {/* <div
                      className={this.toggleCheckActiveTab('tabOrganizers')}
                      onClick={() => this.toggleTab('tabOrganizers')}
                    >
                      Organizers
                    </div> */}
                    {/* <div
                      className={this.toggleCheckActiveTab('tabAboutEvent')}
                      onClick={() => this.toggleTab('tabAboutEvent')}
                    >
                      About the event
                    </div> */}
                  </ul>

                  <div className="tabs-container">
                    {activeTab === 'tabDetail' && eventAll && (
                      <div className="tab-content">
                        <input
                          readOnly
                          ref="forFocus"
                          style={{ border: 'none', cursor: 'none' }}
                        />
                        <Detail event={activeEvent} />
                      </div>
                    )}

                    {activeTab === 'tabZone' && eventAll && (
                      <div className="tab-content">
                        <Zone
                          saveBooking={saveBooking}
                          getlistTalbleNotEmpty={getlistTalbleNotEmpty}
                          loginReducer={loginReducer}
                          goPageLogin={goPageLogin}
                          activeEvent={activeEvent}
                          listTableNotEmpty={listTableNotEmpty}
                        />
                      </div>
                    )}

                    {/* {activeTab === 'tabBuyTicket' && (
                      <div className="tab-content">
                        <Buy />
                      </div>
                    )} */}

                    {/* {activeTab === 'tabOrganizers' && (
                      <div className="tab-content">Organizers</div>
                    )} */}

                    {/* {activeTab === 'tabAboutEvent' && (
                      <div className="tab-content">About the event</div>
                    )} */}
                    <div id="detail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
