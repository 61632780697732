import * as ActionType from '../actions/actionType';

const innitialState = {};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.GET_MEMBER_HISTORY: {
      return {
        ...state,
        historyAll: action.data
      };
    }
    case ActionType.SET_NEXT_PAGE: {
      return {
        ...state,
        nextPage: action.nextPage
      };
    }
    case ActionType.GET_MEMBER_LOGIN: {
      // console.log('inxxxx ', ActionType.GET_MEMBER_LOGIN, action.data);
      if (action.data.flagUpdate) {
        return {
          ...state,
          login: action.data
        };
      }
      if (!action.data.userid || action.data.userid === '') {
        return {
          ...state
        };
      }
      return {
        ...state,
        login: action.data
      };
    }
    case ActionType.SET_MEMBER_LOGOUT: {
      return {};
    }
    default: {
      return state;
    }
  }
};
