import React, { Component } from 'react';
import { Modal, Icon } from 'antd';
import * as ini from '../../../utils/InitialConfig';
import pic1 from '../../../styles/images/stage_1.jpg';

type Props = {
  activeEvent: Object,
  loginReducer: Object,
  choose: Function,
  goPageLogin: Function,
  listTableNotEmpty: Array
};

type State = {};

export default class Seat extends Component<Props, State> {
  handleSelectTable = (id, price, person, ticketId) => {
    const { loginReducer, choose, listTableNotEmpty, goPageLogin } = this.props;
    if (!loginReducer) {
      this.error(ini.errorMsgNotLogin);
      goPageLogin();
      return;
    }
    const splitRowColumn = id.split('-');
    const tmp = id.split('-').join('');

    const resultFind = listTableNotEmpty[0].find(data => data === tmp);
    if (resultFind) {
      this.error(ini.errorMsgTableFull);
    } else {
      choose(
        id,
        splitRowColumn[0] || 0,
        splitRowColumn[1] || 0,
        splitRowColumn[2] || 0,
        price,
        person,
        ticketId
      );
    }
  };

  error = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  handleClickSeat = (id, price, person, ticketId) => {
    console.log(id, price, person, ticketId);
    this.handleSelectTable(id, price, person, ticketId);
  };

  render() {
    const {
      activeEvent,
      activeEvent: { ticketlist },
      listTableNotEmpty
    } = this.props;

    // const items = [];
    // for (let y = 0; y < ticketlist.length; y++) {
    //   const rowSeat = ticketlist[y].seat;
    //   const rowSpit = rowSeat.split('|');
    //   for (let u = 0; u < rowSpit.length; u++) {
    //     if (rowSpit[u] != '') {
    //       const columnSeat = rowSpit[u];
    //       const columnSpit = columnSeat.split(',');
    //       const subRowResult = [];
    //       for (let g = 0; g < columnSpit.length; g++) {
    //         const resultFind = listTableNotEmpty[0].find(
    //           x => x === columnSpit[g].split('-').join('')
    //         );
    //         if (resultFind) {
    //           subRowResult.push(
    //             <span className="span-seat" disabled>
    //               <div className="black">
    //                 <Icon type="user" />
    //                 {columnSpit[g].split('-').join('')}
    //               </div>
    //             </span>
    //           );
    //         } else {
    //           subRowResult.push(
    //             <span
    //               className="span-seat"
    //               onClick={() => {
    //                 this.handleClickSeat(
    //                   columnSpit[g],
    //                   ticketlist[y].price,
    //                   ticketlist[y].person,
    //                   ticketlist[y].ticketId
    //                 );
    //               }}
    //             >
    //               <div className={ticketlist[y].color || 'red'}>
    //                 {columnSpit[g].split('-').join('')}
    //               </div>
    //             </span>
    //           );
    //         }
    //       }
    //       items.push(<div className="div-seat-row">{subRowResult}</div>);
    //     }
    //   }
    // }

    const itemsSpecail = [];

    let cssOldOrNew1 = 'div-theatre';
    let cssOldOrNew2 = 'div-seat-row';

    let tmpSpecialFlag = false; // bodySlam
    let tmpNewFlag = false; // new plan
    let tmpPrice = '0';
    let tmpPerson = '0';
    let tmpTicketId = '0';
    let tmpColor = 'red';

    if (ticketlist.length > 0) {
      tmpSpecialFlag = ticketlist[0].specialFlag === 1 || false;
      tmpNewFlag = ticketlist[0].specialFlag === 0 || false;
      tmpPrice = ticketlist[0].price || '0';
      tmpPerson = ticketlist[0].person || '0';
      tmpTicketId = ticketlist[0].ticketId || '0';
      tmpColor = ticketlist[0].color || 'red';
    }

    if (tmpNewFlag) {
      cssOldOrNew1 = 'div-theatre-new';
      cssOldOrNew2 = 'div-seat-row-new';
    }
    console.log('tmpSpecialFlag', tmpSpecialFlag);
    for (let y = 0; y < ticketlist.length; y++) {
      const rowSeat = ticketlist[y].seat;
      const rowSpit = rowSeat.split('|');
      // const specialFlag = ticketlist[y].specialFlag === 1 || false;
      for (let u = 0; u < rowSpit.length; u++) {
        if (rowSpit[u] != '') {
          const columnSeat = rowSpit[u];
          const columnSpit = columnSeat.split(',');
          const subRowResult = [];
          for (let g = 0; g < columnSpit.length; g++) {

            if (tmpSpecialFlag && y === 1 && (u > 0 && u < 6) && (g > 2 && g < 10)) {
              const tmpSeat = 'A' + '-' + (u) + '-' + (g-2);
              // const tmpSeat = 'A' + numZone;
              // numZone = numZone + 1;
              
              const resultFind = listTableNotEmpty[0].find(
                x => x === tmpSeat.split('-').join('')
              );
              if (resultFind) {
                subRowResult.push(
                  <span className="span-seat" disabled>
                    <div className="black">
                      {/* <Icon type="user" />
                      {tmpSeat.split('-').join('')} */}
                    </div>
                  </span>
                );
              } else {
                subRowResult.push(
                  <span
                    className="span-seat"
                    onClick={() => {
                      this.handleClickSeat(
                        tmpSeat,
                        tmpPrice,
                        tmpPerson,
                        tmpTicketId
                      );
                    }}
                  >
                    <div className={tmpColor || 'red'}>
                      {tmpSeat.split('-').join('')}
                    </div>
                  </span>
                );
              }
            } else if (tmpNewFlag) {
              const resultFind = listTableNotEmpty[0].find(
                x => x === columnSpit[g].split('-').join('')
              );

              const fountainLeft = 'A14,A24';
              const fountainRight = 'A15,A25';
              const fountainBottom = 'A34';
              const fountainBottomRight = 'A35';
            
              if (fountainLeft.indexOf(columnSpit[g].split('-').join('')) >= 0) {
                subRowResult.push(
                  <span className="span-seat-fountain">
                    <div className="fountain-left" />
                  </span>
                );
              } else if (fountainRight.indexOf(columnSpit[g].split('-').join('')) >= 0) {
                subRowResult.push(
                  <span className="span-seat-fountain">
                    <div className="fountain-right" />
                  </span>
                );
              } else if (fountainBottom.indexOf(columnSpit[g].split('-').join('')) >= 0) {
                subRowResult.push(
                  <span className="span-seat-fountain">
                    <div className="fountain-bottom" />
                  </span>
                );
              } else if (fountainBottomRight.indexOf(columnSpit[g].split('-').join('')) >= 0) {
                subRowResult.push(
                  <span className="span-seat-fountain">
                    <div className="fountain-bottom-right" />
                  </span>
                );
              } else if (resultFind) {
                subRowResult.push(
                  <span className="span-seat" disabled>
                    <div className="black">
                      {/* <Icon type="user" />
                      {columnSpit[g].split('-').join('')} */}
                    </div>
                  </span>
                );
              } else {
                subRowResult.push(
                  <span
                    className="span-seat"
                    onClick={() => {
                      this.handleClickSeat(
                        columnSpit[g],
                        ticketlist[y].price,
                        ticketlist[y].person,
                        ticketlist[y].ticketId
                      );
                    }}
                  >
                    <div className={ticketlist[y].color || 'red'}>
                      {columnSpit[g].split('-').join('')}
                    </div>
                  </span>
                );
              }
            } else {
              const resultFind = listTableNotEmpty[0].find(
                x => x === columnSpit[g].split('-').join('')
              );
              
              if (resultFind) {
                subRowResult.push(
                  <span className="span-seat" disabled>
                    <div className="black">
                      {/* <Icon type="user" />
                      {columnSpit[g].split('-').join('')} */}
                    </div>
                  </span>
                );
              } else {
                subRowResult.push(
                  <span
                    className="span-seat"
                    onClick={() => {
                      this.handleClickSeat(
                        columnSpit[g],
                        ticketlist[y].price,
                        ticketlist[y].person,
                        ticketlist[y].ticketId
                      );
                    }}
                  >
                    <div className={ticketlist[y].color || 'red'}>
                      {columnSpit[g].split('-').join('')}
                    </div>
                  </span>
                );
              }
            }
          }
          itemsSpecail.push(<div className={cssOldOrNew2}>{subRowResult}</div>);
        }
      }
    }

    console.log(activeEvent);
    return (
      <div className={cssOldOrNew1}>
        <div className="theatre">
          <div className="theatre-div">
            {
              tmpNewFlag ? <div className="state-new"><img src={pic1} /></div> : 
              <div className="state">
                <span className="state-font">เวที</span>
              </div> 
            }
            
            <div>{itemsSpecail}</div><br />
            {/* {activeEvent.eventId === ini.eventSpecial ? <div>{itemsSpecail}</div> : <div>{items}</div>} */}
            {activeEvent.layoutImage && <img src={activeEvent.layoutImage} alt={activeEvent.artist} />}
          </div>
        </div>
      </div>
    );
  }
}

/* {dataTmp.map((data) => {
              for (let row = 1; row < parseInt(data.row); row++) {
                for (let column = 1; column < parseInt(data.column); column++) {
                  tmp.push(
                    <span> {`${data.title}${row}${column}`}&nbsp;</span>
                  );
                }
              }
              return <span>{tmp}</span>;
            })} */
