import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import { setDataloginFromSession } from '../actions/actionMember';
import * as ini from '../utils/InitialConfig';
import Header from '../components/main/Header';
import Content from '../components/contact';
import Footer from '../components/main/Footer';

const Contact = (props) => {
  useEffect(() => {
    props.dispatch(initApp());
  }, []); // eslint-disable-line

  const handleUpdateLoginBySession = () => {
    const header = window.mainHeader;
    props.dispatch(setDataloginFromSession(header));
  };

  const {
    member: { login }
  } = props;

  return (
    <div>
      <Header
        login={login}
        shopName={ini.shopName}
        setDataloginFromSession={handleUpdateLoginBySession}
      />
      <Content />
      <Footer shopName={ini.shopName} txtFooter={ini.txtFooter} />
    </div>
  );
};

Contact.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(Contact);
