import React, { Component } from 'react';
import { Collapse, Icon } from 'antd';
import * as ini from '../../utils/InitialConfig';
import pic1 from '../../styles/images/news-image-1.jpg';

const { Panel } = Collapse;

type Props = { listData: Object };

type State = {};

export default class NewsData extends Component<Props, State> {
  render() {
    const text = `
    ขอเชิญชม  คอนเสิร์ตการกุศลครั้งสำคัญและพิเศษสุด
    คอนเสิร์ต  จีวันแบนด์ “แจกดวงตา”
    12 ปีบทเพลงธรรม หมุนกงล้อธรรมจักร
     
    พบกับบทเพลงธรรม โดยวงจีวันแบนด์ ที่รังสรรค์แด่ครูบาอาจารย์ ตลอด12  ปีที่ผ่านมา
    นับจากคอนเสิร์ต พุทธทาส-เปิดดวงตา ปี 2550 จนถึงปัจจุบัน
    ที่จีวันแบนด์ รวบรวมมาบรรเลงในคอนเสิร์ตพิเศษนี้  ร่วมกับวงออร์เครสตร้าเยาวชนถึง 2 วงคือ
    ออร์เครสตร้า มาแตร์เดอี โรงเรียนมาแตร์เดอี  และวงรุ่งอรุณป๊อป ออร์เครสตร้า โรงเรียนรุ่งอรุณ
     
    พร้อมกับศิลปินรับเชิญ แห่งชาติ
    จรัญ  ภักดีธนากุล
    สุนทรี เวชานนท์
`;
    const customPanelStyle = {
      background: '#f7f7f7',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden',
      fontSize: '16px',
      fontWeight: 500
    };

    const { listData } = this.props;
    return (
      <div className="tickets-page">
        <div className="row">
          <div className="col-12">
            <div className="event-tickets">
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
              >
                {listData
                  && listData.map((data, index) => {
                    const tmp = (index += 1);
                    return (
                      <Panel
                        header={data.newsTitle}
                        key={tmp}
                        style={customPanelStyle}
                      >
                        <div className="news-image">
                          <img src={data.pathImage} alt="" />
                        </div>
                        <p>{data.newsDescription}</p>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
