/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

class ForgotPasswordIndex extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { save } = this.props;
    const userEmail = e.target.username.value;

    save(userEmail);
  };

  render() {
    const { cancel } = this.props;
    return (
      <div>
        <form className="login-form" onSubmit={this.handleSubmit}>
          <p className="forgot-password-text">
           ลืมรหัสผ่าน
          </p>
          <input
            name="username"
            type="text"
            className="login-username"
            required="true"
            placeholder="ชื่อผู้ใช้ / เมลล์"
          />

          <div className="div-button-login">
            <input
              type="submit"
              name="Login"
              value="ตกลง"
              className="login-submit"
            />

            <input
              type="button"
              name="cancel"
              value="ยกเลิก"
              className="login-submit"
              onClick={cancel}
            />
          </div>
        </form>
        <div className="underlay-photo" />
        <div className="underlay-black" />
      </div>
    );
  }
}
export default ForgotPasswordIndex;
