import React, { Component } from 'react';
import { Link } from 'react-router-dom';

type Props = { login: Object };

type State = {};

export default class Menu extends Component<Props, State> {
  render() {
    const { login } = this.props;
    return (
      <div>
        <ul>
          <li>
            <Link to="/">หน้าแรก</Link>
          </li>
          <li>
            <Link to="/news">ข่าวสาร</Link>
          </li>
          <li>
            <Link to="/buyticket">จองคอนเสิร์ต</Link>
          </li>
          <li>
            <Link to="/contact">ติดต่อเรา</Link>
          </li>
          {login && (
            <li>
              <Link to="/member">สมาชิก</Link>
            </li>
          )}
          {login && (
            <li>
              <Link to="/logout">ออกจากระบบ</Link>
            </li>
          )}
          {!login && (
            <li>
              <Link to="/login">เข้าสู่ระบบ</Link>
            </li>
          )}

          <li>
            {/* <Link to="#">
              <i className="fas fa-search" />
            </Link> */}
          </li>
        </ul>
      </div>
    );
  }
}
