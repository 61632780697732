export const INITAPP = 'INITAPP';
export const GET_EVENT_HOME = 'GET_EVENT_HOME';
export const GET_EVENT_ALL = 'GET_EVENT_ALL';
export const GET_MEMBER_HISTORY = 'GET_MEMBER_HISTORY';
export const GET_MEMBER_LOGIN = 'GET_MEMBER_LOGIN';
export const SET_MEMBER_LOGOUT = 'SET_MEMBER_LOGOUT';
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';
export const AFTER_SAVE_BOOKING = 'AFTER_SAVE_BOOKING';
export const TABLE_NOT_EMPTY = 'TABLE_NOT_EMPTY';
export const GET_NEWS_HOME = 'GET_NEWS_HOME';
