import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import * as serviceWorker from './serviceWorker';

import configureStore from './stories/configureStore';

// import 'babel-polyfill';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles/index.scss';

import './styles/template/webfont.css';
import './styles/template/bootstrap.css';
import './styles/template/bootstrap.min.css';
import './styles/template/fontawesome-all.min.css';
import './styles/template/fontawesome.css';
import './styles/template/fontawesome.min.css';
import './styles/template/swiper.css';
import './styles/template/swiper.min.css';
import './styles/template/style.css';
import 'antd/dist/antd.css';

const initialState = {};
const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store} key="provider">
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
