import * as ActionType from '../actions/actionType';

const innitialState = {};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.GET_EVENT_HOME: {
      return {
        ...state,
        eventHome: action.data
      };
    }
    case ActionType.GET_EVENT_ALL: {
      return {
        ...state,
        eventAll: action.data
      };
    }
    case ActionType.AFTER_SAVE_BOOKING: {
      return {
        ...state
      };
    }
    case ActionType.TABLE_NOT_EMPTY: {
      return {
        ...state,
        listTableNotEmpty: action.data
      };
    }
    case ActionType.GET_NEWS_HOME: {
      return {
        ...state,
        newsData: action.data
      };
    }
    default: {
      return state;
    }
  }
};
