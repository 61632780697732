import React, { Component } from 'react';

type Props = { shopName: String, txtFooter: String };

type State = {};

export default class Footer extends Component<Props, State> {
  render() {
    const { shopName, txtFooter } = this.props;
    return (
      <footer className="site-footer">
        <div className="footer-cover-title flex justify-content-center align-items-center">
          <h2>{shopName}</h2>
        </div>

        <div className="footer-content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-title">
                  <div>{shopName}</div>
                </div>

                <div className="entry-mail">
                  <div>{txtFooter}</div>
                </div>

                <div className="copyright-info">
                  Copyright &copy; 2019
                  {/* <script>
                      document.write(new Date().getFullYear());
                    </script>{' '}
                    All rights reserved | This template is made with{' '}
                    <i className="fa fa-heart" aria-hidden="true" /> by{' '}
                    <a href="https://colorlib.com" target="_blank">
                      Colorlib
                    </a> */}
                </div>

                <div className="footer-social">
                  <ul className="flex justify-content-center align-items-center">
                    <li>
                      <div>
                        <i className="fab fa-pinterest" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <i className="fab fa-facebook-f" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <i className="fab fa-twitter" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <i className="fab fa-dribbble" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <i className="fab fa-behance" />
                      </div>
                    </li>
                    <li>
                      <div>
                        <i className="fab fa-linkedin-in" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
