import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { message, Spin, Icon } from 'antd';
import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import {
  getHistoryAll,
  setDataloginFromSession
} from '../actions/actionMember';
import { editBooking } from '../actions/actionBuyTicket';
import * as ini from '../utils/InitialConfig';
import Header from '../components/main/Header';
import Content from '../components/member';
import Footer from '../components/main/Footer';

const Member = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  const errors = (msg) => {
    message.error(msg);
  };

  const getDataHistory = () => {
    toggleLoading(true);
    props.dispatch(
      getHistoryAll((status, err) => {
        toggleLoading(false);
        if (status !== ini.success) {
          errors(err);
        }
      })
    );
  };

  useEffect(() => {
    props.dispatch(initApp());
    getDataHistory();
    // toggleLoading(true);
    // props.dispatch(
    //   getHistoryAll((status, err) => {
    //     toggleLoading(false);
    //     if (status !== ini.success) {
    //       errors(err);
    //     }
    //   })
    // );
  }, []); // eslint-disable-line

  const success = (msg) => {
    message.success(msg);
  };

  const handleEditBooking = (data) => {
    toggleLoading(true);
    props.dispatch(
      editBooking(data, (status, err) => {
        toggleLoading(false);
        if (status === ini.success) {
          success(ini.saveSuccess);
          getDataHistory();
        } else {
          errors(err);
        }
      })
    );
  };

  const {
    member,
    member: { login }
  } = props;

  const handleUpdateLoginBySession = () => {
    const header = window.mainHeader;
    props.dispatch(setDataloginFromSession(header));
  };

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );

  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <Header
          login={login}
          shopName={ini.shopName}
          setDataloginFromSession={handleUpdateLoginBySession}
        />
        <Content
          memberReducer={member}
          editBooking={handleEditBooking}
          getDataHistory={getDataHistory}
        />
        <Footer shopName={ini.shopName} txtFooter={ini.txtFooter} />
      </Spin>
    </div>
  );
};

Member.propTypes = {
  dispatch: PropTypes.func.isRequired,
  member: PropTypes.shape().isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(Member);
