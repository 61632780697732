export const shopName = 'Buono Dine&Wine';
export const txtFooter = 'SAYHELLO@BUONO.COM';
export const mail = 'buonoratchaburi@gmail.com';
export const lineId = '@buonodineandwine';
export const tel = '032-720-577';
export const ageRegister = 18;
export const errorTitle = 'ข้อผิดพลาด';
export const errorMsgDefault = 'เกิดข้อผิดพลาดจากระบบ โปรดติดต่อเจ้าหน้าที่';
export const errorMsgNotLogin = 'กรุณาเข้าสู่ระบบ ก่อนทำการจองโต๊ะ';
export const errorMsgTableFull = 'โต๊ะนี้ถูกจองแล้ว กรุณาเลือกโต๊ะอื่น';
export const errorPass = 'รหัสผ่านไม่ถูกต้อง';
export const errorPassConfirmPassNotMatch = 'รหัสผ่านและรหัสผ่านยืนยันไม่ตรงกัน';
export const errorInvalidBirthday = 'กรุณากรอกวันเกิด';
export const errorAgeInvalid = `คุณต้องมีอายุตั้งแต่ ${ageRegister} ขึ้นไป`;
export const errorInvalidPhone = 'กรุณาเบอร์โทรศัพท์ให้ถูกต้อง';
export const errorPaidNotAttatchSlip = 'โปรดแนบ Slip';
export const errorCallEventAll = 'ไม่สามารถค้นหาข้อมูล Event โปรดติดต่อเจ้าหน้าที่';
export const errorRegister = 'ไม่สามารถบันทึกข้อมูลได้ โปรดติดต่อเจ้าหน้าที่';
export const errorForgotPassword = 'ไม่สามารถส่งข้อมูลการเปลี่ยนรหัสผ่านได้ โปรดติดต่อเจ้าหน้าที่';
export const errorUpdatePassword = 'ไม่สามารถเปลี่ยนรหัสผ่านได้ โปรดติดต่อเจ้าหน้าที่';
export const errorLoginUserPassInvalid = 'User/Password ไม่ถูกต้อง';
export const errorGetBookedTicketError = 'ไม่สามารถค้นหาข้อมูลโต๊ะที่ถูกจองแล้ว โปรดติดต่อเจ้าหน้าที่';
export const errorEventNotFound = 'ไม่พบข้อมูลของคุณ';
export const errorChangePassKeyNotValid = 'เกิดข้อผิดพลาด กรุณาทำตามขั้นตอนการเปลี่ยนรหัสผ่าน / กรุณาติดต่อเจ้าหน้าที่';

export const saveSuccess = 'บันทึกข้อมูลเรียบร้อย';

export const confirmDelete = 'คุณต้องการลบข้อมูลการจองหรือไม่ ?';

export const end = 'End';
export const onSale = 'Onsale';
export const endTH = 'จบแล้ว';
export const qrcode = 'QRCODE';
export const paid = 'PAID';
export const notPaid = 'ยังไม่ชำระเงิน';
export const paidTH = 'แนบสลิป';
export const cancelTH = 'ลบ';

export const N = 'N';
export const Y = 'Y';
export const error = 'ERROR';
export const success = 'SUCCESS';

export const keyApp = 'Basic bWluZGFvbm91YjpidTBuMEByQGRyZWU=';
export const keyChangePass = 'YnVvbm9zZWN1cmU=';

export const eventSpecial = '13';
