import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Spin, Icon } from 'antd';
import { connectRedux } from '../utils';
import { logout } from '../actions/actionMember';

const Logout = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  useEffect(() => {
    toggleLoading(true);
    props.dispatch(
      logout(() => {
        toggleLoading(false);
        props.history.push('/');
        window.location.reload();
      })
    );
  }, []); // eslint-disable-line

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );

  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        logout
      </Spin>
    </div>
  );
};

Logout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(Logout);
