/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import * as ini from '../../utils/InitialConfig';

class LoginIndex extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { getLogin } = this.props;
    const data = {
      user: e.target.username.value,
      pass: e.target.password.value
    };

    getLogin(data);
  };

  render() {
    const { memberReducer, goPageRegister, goPageForgotPassword, cancel } = this.props;
    console.log('memberReducer', memberReducer);
    return (
      <div>
        <form className="login-form" onSubmit={this.handleSubmit}>
          <p className="login-text">
            <span className="fa-stack fa-lg">
              <i className="fa fa-circle fa-stack-2x" />
              <i className="fa fa-lock fa-stack-1x" />
            </span>
          </p>
          <input
            name="username"
            type="text"
            className="login-username"
            required="true"
            placeholder="ชื่อผู้ใช้"
          />
          <input
            name="password"
            type="password"
            className="login-password"
            required="true"
            placeholder="รหัสผ่าน"
          />
          {/* <center>
            <div className="div-forgot-password ">
              ** หากลืม password กรุณาติดต่อ Admin ที่
              <br /> Line ID : {ini.lineId} / Tel : {ini.tel} / Mail :{ini.mail}
            </div>
          </center> */}

          <div className="div-button-login">
            <input
              type="submit"
              name="Login"
              value="เข้าสู่ระบบ"
              className="login-submit"
            />

            {/* <input
              type="button"
              name="Register"
              value="สมัครสมาชิก"
              className="login-submit"
              onClick={() => goPageRegister()}
            /> */}
            
            <input
              type="button"
              name="ForgotPassword"
              value="ลืมรหัสผ่าน"
              className="login-submit"
              onClick={() => goPageForgotPassword()}
            />

            <input
              type="button"
              name="cancel"
              value="ยกเลิก"
              className="login-submit"
              onClick={cancel}
            />
          </div>
        </form>

        {/* <a href="#" className="login-forgot-pass">
          Register
        </a> */}
        <div className="underlay-photo" />
        <div className="underlay-black" />
      </div>
    );
  }
}
export default LoginIndex;
