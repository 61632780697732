import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Spin, Icon } from 'antd';
import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import LoginIndex from '../components/login';
import { getLogin } from '../actions/actionMember';
import * as ini from '../utils/InitialConfig';

const Login = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  useEffect(() => {
    props.dispatch(initApp());
  }, []); // eslint-disable-line

  const alertError = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  const handleSubmitLogin = (value) => {
    toggleLoading(true);
    props.dispatch(
      getLogin(value, (status, err) => {
        toggleLoading(false);
        if (status === ini.success) {
          if (member.nextPage) {
            props.history.push(`/${member.nextPage}`);
          } else {
            props.history.push('/');
          }
        } else {
          alertError(err);
        }
      })
    );
  };

  const handleCancel = () => {
    props.history.push('/');
  };

  const goPageRegister = () => {
    props.history.push('/register');
  };

  const goPageForgotPassword = () => {
    props.history.push('/forgotpassword');
  };

  const { member } = props;

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );

  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <LoginIndex
          getLogin={handleSubmitLogin}
          goPageRegister={goPageRegister}
          memberReducer={member}
          goPageForgotPassword={goPageForgotPassword}
          cancel={handleCancel}
        />
      </Spin>
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  member: PropTypes.shape().isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(Login);
