import React, { Component } from 'react';
import NewsData from './NewsData';

type Props = { listData: Object };

type State = { activeTab: string, activeEvent: Object };

export default class NewsIndex extends Component<Props, State> {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { listData } = this.props;
    return (
      <div className="news-page">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-header">
                  <h2 className="entry-title">ข่าวสาร</h2>

                  <ul className="breadcrumbs flex align-items-center">
                    <li>
                      <a href="#">หน้าแรก</a>
                    </li>
                    <li>ข่าวสาร</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="main-content">
            <h2>ข่าวสาร</h2>
            <NewsData listData={listData} />
          </div>
        </div>
      </div>
    );
  }
}
