import { GET, POST } from './api';
import {
  LOGIN,
  REGISTER,
  UPDATE_USER,
  EVENT_HOME,
  EVENT_ALL,
  BOOKED_TICKET,
  HISTORY,
  SAVE_BOOKING,
  UPDATE_BOOKING,
  NEWS,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD
} from './apiList';

export const login = (data) => {
  const base64Pass = window.btoa(data.pass);
  const queryString = `${LOGIN}?username=${data.user}&password=${base64Pass}`;
  return GET(queryString, 1500);
};
export const forgotPassword = (userEmail) => {
  const queryString = `${FORGOT_PASSWORD}?useremail=${userEmail}`;
  return GET(queryString, 1500);
};

export const updatePassword = data => POST(UPDATE_PASSWORD, data, 1500);
export const register = data => POST(REGISTER, data, 1500);
export const updateUser = data => POST(UPDATE_USER, data, 1500);
export const saveBooking = data => POST(SAVE_BOOKING, data, 1500);
export const editBooking = data => POST(UPDATE_BOOKING, data, 1500);
export const callEventAll = () => GET(EVENT_ALL, 1500);
export const callEventHome = () => GET(EVENT_HOME, 1500);
export const callNews = () => GET(NEWS, 1500);
export const getBookedTicket = (eventId) => {
  const queryString = `${BOOKED_TICKET}?evenid=${eventId}`;
  return GET(queryString, 1500);
};

export const callHistory = (userId) => {
  const queryString = `${HISTORY}?userId=${userId}`;
  return GET(queryString, 1500);
};
