import React, { Component } from 'react';

import mail from '../../styles/images/mail.jpg';
import phone from '../../styles/images/phone.jpg';
import place from '../../styles/images/place.jpg';
import * as ini from '../../utils/InitialConfig';

type Props = {};

type State = { activeTab: string, activeEvent: Object };

export default class ContactIndex extends Component<Props, State> {
  render() {
    return (
      <div className="contact-page">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-header">
                  <h2 className="entry-title">ติดต่อเรา</h2>

                  <ul className="breadcrumbs flex align-items-center">
                    <li>
                      <a href="#">หน้าแรก</a>
                    </li>
                    <li>ติดต่อเรา</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="main-content">
            <div className="contact-page-map">
              <iframe
                id="gmap_canvas"
                title="gmap_canvas"
                src="https://her.is/2P1OaX9"
                // src="https://maps.google.com/maps?q=university of san francisco&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              />
            </div>

            <div className="contact-details">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="contact-medium flex align-items-center">
                      <figure className="contact-icon">
                        <img src={phone} alt="phone icon" />
                      </figure>

                      <div className="contact-content">
                        <div className="entry-title">Phone</div>
                        <div className="entry-content">{ini.tel}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mt-5 mt-md-0">
                    <div className="contact-medium flex align-items-center">
                      <figure className="contact-icon">
                        <img src={mail} alt="phone icon" />
                      </figure>

                      <div className="contact-content">
                        <div className="entry-title">E-mail</div>
                        <div className="entry-content">{ini.mail}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mt-5 mt-lg-0">
                    <div className="contact-medium flex align-items-center">
                      <figure className="contact-icon">
                        <img src={place} alt="phone icon" />
                      </figure>

                      <div className="contact-content">
                        <div className="entry-title">Line ID</div>
                        <div className="entry-content">{ini.lineId}</div>
                      </div>
                      {/* <div className="contact-content">
                        <div className="entry-title">Address</div>
                        <div className="entry-content">
                          478 หมู่ที่ 2 ตำบลบ้านไร่
                          <br />
                          เทศบาลเมืองราชบุรี ราชบุรี
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="get-in-touch">
              <div className="entry-header">
                <div className="entry-title">
                  <h2>Buono Dine&Wine</h2>
                </div>
              </div>
              <div className="entry-content">
                <p>
                  Buono Dine&Wine ร้านอาหารสไตล์อิตาเลี่ยน-ฝรั่งเศส
                  จุดเด่นของเรา คือ เน้นคุณภาพของสเต็กที่คัดสรรมาเป็นอย่างดี
                  มีทั้งเครื่องดื่มมากมายหลายชนิด บรรยากาศร้านสบายๆ
                  ลูกค้าสามารถนั่งในห้องแอร์เย็นๆ
                  หรือจะนั่งชิลล์เอ้าท์บริเวณลานด้านนอก ก็สามารถทำได้
                  และยังมีนักร้องเสียงคุณภาพ เล่นดนตรีสดให้ฟังทุกวันอีกด้วย
                </p>
              </div>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
