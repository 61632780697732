import React, {Component} from 'react';
import Modal from 'react-modal-es';
import {Modal as ModalAnt, Button, message} from 'antd';
import pic1 from '../../styles/images/pic_sold4.png';
import pic2 from '../../styles/images/pic_comingsoon1.png';
import * as ini from '../../utils/InitialConfig';
import {openModal, closeModal} from '../modal';
import Seat from './seat';

type Props = {
  saveBooking: Function,
  getlistTalbleNotEmpty: Function,
  goPageLogin: Function,
  loginReducer: Object,
  activeEvent: Object,
  listTableNotEmpty: Object,
};

type State = {
  idTable: string,
  title: String,
  row: string,
  column: string,
  price: string,
  person: string,
  ticketId: string,
  isConfirm: boolean,
  qr: string,
};

export default class Zone extends Component<Props, State> {
  state = {
    idTable: '',
    title: '',
    row: '',
    column: '',
    price: '',
    person: '',
    ticketId: '',
    isConfirm: false,
    qr: '',
  };

  success = msg => {
    message.success(msg);
  };

  handleUpdateValue = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleSelectTable = (id, title, row, column, price, person, ticketId) => {
    this.setState({
      idTable: id,
      title,
      row,
      column,
      price,
      person,
      ticketId,
    });
    openModal('modalTicket');
  };

  save = () => {
    const {saveBooking, activeEvent, loginReducer} = this.props;
    const {idTable, title, row, column, price, ticketId} = this.state;
    const dataQR = `${loginReducer.userid}-${
      activeEvent.eventId
    }-${ticketId}-${idTable.split('-').join('')}`;
    const qrShow = dataQR.split('-').join('');

    const dataSave = {
      eventId: activeEvent.eventId,
      ticketId,
      bookingAmount: price,
      userId: loginReducer.userid,
      bookingFirstName: loginReducer.fullname,
      bookingLastName: loginReducer.fullname,
      bookingEmail: loginReducer.email,
      bookingPhone: loginReducer.phone,
      chanelId: loginReducer.chanelId,
      bookingDiscount: 0,
      bookingQuantity: 0,
      createBy: loginReducer.userid,
      bookingPaid: 0,
      deleteFlag: 0,
      cancelFlag: 0,
      bookingBarcode: dataQR.split('-').join(''),
      bookingId: 0,
      ticketTitle: title,
      row,
      column,
    };

    saveBooking(dataSave, status => {
      const {getlistTalbleNotEmpty, activeEvent} = this.props;
      if (ini.Y === status) {
        this.setState({
          isConfirm: true,
          qr: qrShow,
        });
        // this.renderQRCode(qrShow);
        this.toggleCloseModal();
        getlistTalbleNotEmpty(activeEvent.eventId);
      } else {
        this.toggleCloseModal();
      }
    });
  };

  renderQRCode = dataQR => {
    // eslint-disable-next-line no-undef
    const qrcode = new QRCode('qrcode');
    qrcode.makeCode(dataQR);
  };

  toggleCloseModal = () => {
    this.setState({
      isConfirm: false,
      qr: '',
    });
    document.getElementById('qrcode').innerHTML = '';
    closeModal('modalTicket');
  };

  handleCloseAllModal = () => {
    this.setState({
      txtDetail: '',
      imageUrl: '',
    });
    closeModal('modalTicket');
  };

  error = txt => {
    ModalAnt.error({
      title: ini.errorTitle,
      content: txt,
      centered: true,
    });
  };

  render() {
    const {
      loginReducer,
      listTableNotEmpty,
      goPageLogin,
      activeEvent: {eventTitle, startDate},
      activeEvent,
    } = this.props;
    const {idTable, price, person, isConfirm, qr} = this.state;

    return (
      <div>
        <Modal name="modalTicket" overlayColor="rgba(255, 255, 255, 0.7)">
          <div className="ticket">
            <div className="ticket-body">
              <div className="head">
                <span className="event">{eventTitle}</span>
              </div>
              {!isConfirm && (
                <div className="contents">
                  <ul>
                    <li>
                      <span className="labels">NAME</span>
                      <span className="data">
                        {loginReducer ? loginReducer.fullname : ''}
                      </span>
                    </li>
                    <li>
                      <span className="labels">DATE</span>
                      <span className="data">{startDate}</span>
                    </li>
                    <li>
                      <span className="labels">TABLE</span>
                      <span className="data">
                        {idTable.split('-').join('')}
                      </span>
                    </li>
                    <li>
                      <span className="labels">จำนวนคน</span>
                      <span className="data">{person}</span>
                    </li>
                    <li>
                      <span className="labels">ราคา</span>
                      <span className="data">{price}</span>
                    </li>
                  </ul>
                </div>
              )}

              <div className="contents-qrcode">
                {qr && <center className="div-code-qr">{qr}</center>}
                <div id="qrcode" className={isConfirm ? 'qrcode' : ''} />
              </div>

              <div className="footer">
                {!isConfirm ? (
                  <div>
                    <Button onClick={this.toggleCloseModal}>ยกเลิก</Button>
                    &nbsp;&nbsp;
                    <Button onClick={() => this.save()}>ยืนยันการจอง</Button>
                  </div>
                ) : (
                  <div>
                    <Button onClick={this.toggleCloseModal}>ปิด</Button>
                    {/* &nbsp;&nbsp;
                    <Button onClick={() => this.toggleClickPaid()}>จ่าย</Button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>

        {(activeEvent.openFlag && activeEvent.openFlag === 2 && (
          <center>
            <img
              src={pic1}
              alt="Sold out!!!"
              style={{transform: 'rotate(5deg)'}}
            />
          </center>
        )) ||
          (activeEvent.openFlag &&
            activeEvent.openFlag === 1 &&
            activeEvent.ticketlist.length > 0 && (
              <Seat
                activeEvent={activeEvent}
                loginReducer={loginReducer}
                choose={this.handleSelectTable}
                goPageLogin={goPageLogin}
                listTableNotEmpty={listTableNotEmpty}
              />
            )) || (
            <center>
              &nbsp;&nbsp;&nbsp;
              <img
                src={pic2}
                alt="Coming Soon!!!"
                style={{transform: 'rotate(20deg)', margin: '-18%'}}
              />
              <br />
              <br />
              <br />
              <br />
            </center>
          )}
      </div>
    );
  }
}
