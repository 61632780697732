import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, message, Spin, Icon} from 'antd';
import {connectRedux} from '../utils';
import {initApp} from '../actions/action';
import {
  getEventAll,
  saveBooking,
  getlistTalbleNotEmpty,
} from '../actions/actionBuyTicket';
import {setDataloginFromSession} from '../actions/actionMember';
import * as ini from '../utils/InitialConfig';
import Header from '../components/main/Header';
import Content from '../components/buyTicket';
import Footer from '../components/main/Footer';

const BuyTicket = props => {
  const [state, setState] = useState(() => {
    const initialState = {showLoading: false};
    return initialState;
  });

  const toggleLoading = status => {
    setState({
      showLoading: status,
    });
  };

  const success = msg => {
    message.success(msg);
  };

  const alertData = (type, txt) => {
    if (type === ini.error) {
      Modal.error({
        title: ini.errorTitle,
        content: txt,
        centered: true,
      });
    } else {
      Modal.success({
        content: txt,
        centered: true,
      });
    }
  };

  const errors = msg => {
    message.error(msg);
  };

  useEffect(() => {
    props.dispatch(initApp());
    toggleLoading(true);
    props.dispatch(
      getEventAll((status, res) => {
        if (status === ini.success) {
          props.dispatch(
            getlistTalbleNotEmpty(res, (statusBooked, err) => {
              if (statusBooked === ini.error) {
                errors(err);
              }
              toggleLoading(false);
            })
          );
        } else {
          errors(res);
          // props.history.push('/');
          toggleLoading(false);
        }
      })
    );
  }, []); // eslint-disable-line

  const handleUpdateLoginBySession = () => {
    const header = window.mainHeader;
    props.dispatch(setDataloginFromSession(header), () => {});
  };

  const handleSaveBooking = (dataQR, callBack) => {
    toggleLoading(true);
    props.dispatch(
      saveBooking(dataQR, (status, err) => {
        toggleLoading(false);
        if (status === ini.success) {
          // success(ini.saveSuccess);
          alertData(ini.success, ini.saveSuccess);
          callBack(ini.Y);
        } else {
          alertData(ini.error, err);
          callBack(ini.N);
        }
      })
    );
  };

  const handleGetlistTalbleNotEmpty = eventId => {
    toggleLoading(true);
    props.dispatch(getlistTalbleNotEmpty(eventId, () => {}));
    toggleLoading(false);
  };

  const handleGopageLogin = () => {
    props.history.push('/login');
  };

  const {
    event,
    member: {login},
  } = props;

  const antIcon = (
    <Icon type="loading" style={{fontSize: 50, color: '#000'}} spin />
  );

  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <Header
          login={login}
          shopName={ini.shopName}
          setDataloginFromSession={handleUpdateLoginBySession}
        />
        <Content
          eventReducer={event}
          loginReducer={login}
          saveBooking={handleSaveBooking}
          goPageLogin={handleGopageLogin}
          getlistTalbleNotEmpty={handleGetlistTalbleNotEmpty}
        />
        <Footer shopName={ini.shopName} txtFooter={ini.txtFooter} />
      </Spin>
    </div>
  );
};

BuyTicket.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,
};

const mapState = state => state;

export default connectRedux(mapState)(BuyTicket);
