import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Spin, Icon, message } from 'antd';
import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import ForgotPasswordIndex from '../components/login/ForgotPassword';
import { getForgotPassword } from '../actions/actionMember';
import * as ini from '../utils/InitialConfig';

const ForgotPassword = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  useEffect(() => {
    props.dispatch(initApp());
  }, []); // eslint-disable-line

  const success = (msg) => {
    message.success(msg);
  };

  const alertError = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  const handleSubmit = (value) => {
    toggleLoading(true);
    props.dispatch(
      getForgotPassword(value, (status, err) => {
        toggleLoading(false);
        if (status === ini.success) {
          success(ini.saveSuccess);
          props.history.push('/');
        } else {
          alertError(err);
        }
      })
    );
  };


  const handleCancel = () => {
    props.history.push('/');
  }

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );

  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <ForgotPasswordIndex save={handleSubmit} cancel={handleCancel} />
      </Spin>
    </div>
  );
};

ForgotPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(ForgotPassword);
