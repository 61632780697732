import React, { Component } from 'react';

import OldEvent from './OldEvent';
import picBox from '../../styles/images/box.jpg';
// import pic1 from '../../styles/images/home2.jpg';
// import pic2 from '../../styles/images/home1.jpg';
// import pic3 from '../../styles/images/home3.jpg';
// import pic1 from '../../styles/images/black-chick.jpg';
// import pic2 from '../../styles/images/mathew-kane.jpg';
// import pic3 from '../../styles/images/eric-ward.jpg';

type Props = { eventHome: Array, newsData: Object };

type State = {};

export default class Content extends Component<Props, State> {
  render() {
    const { eventHome, newsData } = this.props;
    let desc0;
    let title0;
    let pic0;
    let desc1;
    let title1;
    let pic1;
    let desc2;
    let title2;
    let pic2;
    if (eventHome) {
      if (eventHome[0]) {
        const countTitle = eventHome[0].eventTitle.length;
        let strTitle = eventHome[0].eventTitle;
        strTitle = countTitle <= 16 ? strTitle : `${strTitle.substring(0, 16 + 1)}...`;
        title0 = strTitle;

        const countData = eventHome[0].eventDescription.length;
        let strDesc = eventHome[0].eventDescription;
        strDesc = countData <= 200 ? strDesc : `${strDesc.substring(0, 200 + 1)}...`;
        desc0 = strDesc;

        pic0 = eventHome[0].pathImage;
      }

      if (eventHome[1]) {
        const countTitle = eventHome[1].eventTitle.length;
        let strTitle = eventHome[1].eventTitle;
        strTitle = countTitle <= 16 ? strTitle : `${strTitle.substring(0, 16 + 1)}...`;
        title1 = strTitle;

        const countData = eventHome[1].eventDescription.length;
        let strDesc = eventHome[1].eventDescription;
        strDesc = countData <= 200 ? strDesc : `${strDesc.substring(0, 200 + 1)}...`;
        desc1 = strDesc;

        pic1 = eventHome[1].pathImage;
      }

      if (eventHome[2]) {
        const countTitle = eventHome[2].eventTitle.length;
        let strTitle = eventHome[2].eventTitle;
        strTitle = countTitle <= 16 ? strTitle : `${strTitle.substring(0, 16 + 1)}...`;
        title2 = strTitle;

        const countData = eventHome[2].eventDescription.length;
        let strDesc = eventHome[2].eventDescription;
        strDesc = countData <= 200 ? strDesc : `${strDesc.substring(0, 200 + 1)}...`;
        desc2 = strDesc;

        pic2 = eventHome[2].pathImage;
      }
    }

    return (
      <div className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lineup-artists-headline">
                <div className="entry-title">
                  <h2>คอนเสิร์ต</h2>
                </div>

                <div className="lineup-artists">
                  {eventHome && eventHome[0] && (
                    <div className="lineup-artists-wrap flex flex-wrap">
                      <figure className="featured-image">
                        <a href="/#/buyticket">
                          <img src={pic0} alt="" />
                        </a>
                      </figure>

                      <div className="lineup-artists-description">
                        <div className="lineup-artists-description-container">
                          <div className="entry-title">{title0}</div>

                          <div className="entry-content">
                            <p>{desc0}</p>
                          </div>

                          <div className="box-link">
                            <a href="/#/buyticket">
                              <img src={picBox} alt="#" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {eventHome && eventHome[1] && (
                    <div className="lineup-artists-wrap flex flex-wrap">
                      <div className="lineup-artists-description">
                        <figure className="featured-image d-md-none">
                          <a href="/#/buyticket">
                            <img src={pic1} alt="" />
                          </a>
                        </figure>

                        <div className="lineup-artists-description-container">
                          <div className="entry-title">{title1}</div>

                          <div className="entry-content">
                            <p>{desc1}</p>
                          </div>

                          <div className="box-link">
                            <a href="/#/buyticket">
                              <img src={picBox} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>

                      <figure className="featured-image d-none d-md-block">
                        <a href="/#/buyticket">
                          <img src={pic2} alt="" />
                        </a>
                      </figure>
                    </div>
                  )}

                  {eventHome && eventHome[2] && (
                    <div className="lineup-artists-wrap flex flex-wrap">
                      <figure className="featured-image">
                        <a href="/#/buyticket">
                          <img src={pic2} alt="" />
                        </a>
                      </figure>

                      <div className="lineup-artists-description">
                        <div className="lineup-artists-description-container">
                          <div className="entry-title">{title2}</div>
                          <div className="entry-content">
                            <p>{desc2}</p>
                          </div>

                          <div className="box-link">
                            <a href="/#/buyticket">
                              <img src={picBox} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <OldEvent newsData={newsData} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
