/* eslint-disable no-script-url */
import React, { Component } from 'react';
import Slider from 'react-slick';

type Props = { eventAll: Object, clickEvent: Function };

type State = {};

export default class EventAll extends Component<Props, State> {
  render() {
    const { eventAll, clickEvent } = this.props;
    const sumEvent = (eventAll && eventAll.length) || 0;
    const slide = sumEvent > 2 ? 3 : sumEvent;

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: slide,
      slidesToScroll: slide,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slide,
            slidesToScroll: slide,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: slide,
            slidesToScroll: slide,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div>
        <h2>คอนเสิร์ต</h2>
        <br />
        <Slider {...settings}>
          {eventAll
            && eventAll.map((data) => {
              return (
                <div className="event-image">
                  <a href="Javascript:;" onClick={() => clickEvent(data)}>
                    <img src={data.eventImage} alt={data.artist} />
                  </a>
                </div>
              );
            })}
        </Slider>
      </div>
    );
  }
}
