import React, {Component} from 'react';
import Modal from 'react-modal-es';
import {
  Modal as ModalAnt,
  Popconfirm,
  Button,
  Upload,
  Icon,
  message,
  Input,
} from 'antd';

import {UPLOAD_SLIP} from '../../service/apiList';

import {openModal, closeModal} from '../modal';
import * as ini from '../../utils/InitialConfig';

type Props = {
  historyAll: Object,
  editBooking: Function,
  getDataHistory: Function,
};

type State = {eventName: string, qr: String, bookingId: string};

export default class History extends Component<Props, State> {
  state = {
    eventName: '',
    qr: '',
    bookingId: '',
    visibleModalDetailPaid: false,
  };

  success = msg => {
    message.success(msg);
  };

  handleUpdateValue = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleClickPaid = (dataQR, eventName, bookingId) => {
    this.setState({
      eventName,
      qr: dataQR,
      bookingId,
    });
    openModal('modalPaid');
  };

  handleClickCancel = data => {
    // console.log('cancel', data);
    const {editBooking} = this.props;
    const dataSave = {
      bookingId: data.bookingId,
      cancelFlag: 1,
      bookingAmount: 0,
      bookingBarcode: '',
      bookingDiscount: 0,
      bookingEmail: '',
      bookingFirstName: '',
      bookingLastName: '',
      bookingPaid: 0,
      bookingPhone: '',
      bookingQuantity: 0,
      chanelId: 0,
      column: 0,
      createBy: 0,
      deleteFlag: 0,
      eventId: 0,
      row: 0,
      ticketId: 0,
      ticketTitle: '',
      userId: 0,
    };
    editBooking(dataSave);
  };

  handleClickQR = (status, dataQR, eventName, bookingId) => {
    this.setState({
      eventName,
      qr: dataQR,
      bookingId,
    });

    const myInterval = setInterval(() => {
      openModal('modalQR');
      this.renderQRCode(dataQR);
      clearInterval(myInterval);
    }, 100);
  };

  renderQRCode = dataQR => {
    // eslint-disable-next-line no-undef
    const qrcodeOld = new QRCode('qrcodeOld');
    qrcodeOld.makeCode(dataQR);
  };

  toggleCloseModal = () => {
    document.getElementById('qrcodeOld').innerHTML = '';
    closeModal('modalQR');
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      this.alertData(ini.error, 'You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      this.alertData(ini.error, 'Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  alertData = (type, txt) => {
    if (type === ini.error) {
      ModalAnt.error({
        title: ini.errorTitle,
        content: txt,
        centered: true,
      });
    } else {
      ModalAnt.success({
        content: txt,
        centered: true,
      });
    }
  };

  handleChange = info => {
    // console.log('info', info);
    if (info.file.status === 'uploading') {
      this.setState({loading: true});
      return;
    }
    if (info.file.status === 'done') {
      if (info.file.response.code === ini.Y) {
        // this.success(info.file.response.description);
        this.alertData(ini.success, info.file.response.description);
      } else {
        // this.error(info.file.response.description);
        this.alertData(ini.error, info.file.response.description);
      }
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  handleClosePaid = () => {
    const {getDataHistory} = this.props;
    this.setState({
      imageUrl: '',
    });
    getDataHistory();
    closeModal('modalPaid');
  };

  error = txt => {
    ModalAnt.error({
      title: ini.errorTitle,
      content: txt,
      centered: true,
    });
  };

  confirm = data => {
    this.handleClickCancel(data);
  };

  handelClickDetailPaid = () => {
    this.setState({
      visibleModalDetailPaid: true,
    });
  };

  handleCancelDetailPaid = e => {
    console.log(e);
    this.setState({
      visibleModalDetailPaid: false,
    });
  };

  render() {
    const {historyAll} = this.props;
    const {eventName, qr, bookingId} = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {imageUrl} = this.state;
    const {TextArea} = Input;
    return (
      <div className="tickets-page">
        <div className="row">
          <div className="col-12">
            <div className="event-tickets">
              <Button
                onClick={this.handelClickDetailPaid}
                type="primary"
                icon="check-circle"
              >
                โอนเงิน
              </Button>
              <ModalAnt
                title="โอนเงิน"
                visible={this.state.visibleModalDetailPaid}
                onCancel={this.handleCancelDetailPaid}
                footer={[
                  <Button key="close" onClick={this.handleCancelDetailPaid}>
                    ปิด
                  </Button>,
                ]}
              >
                <p>โอนเงินเข้าบัญชี</p>
                <p>ธนาคารกรุงศรีอยุธยา สาขาราชบุรี</p>
                <p>เลขที่บัญชี 119-1-63888-4</p>
                <p>ชื่อบัญชี บจก.บูโอโน</p>
              </ModalAnt>
              <br />
              <br />

              {historyAll.length === 0 && (
                <center>
                  <div className="div-event-notfound">
                    {ini.errorEventNotFound}
                  </div>
                </center>
              )}

              {historyAll &&
                historyAll.map(data => {
                  let styleStatus = 'btn btn-process-end';
                  if (data.eventStatus === ini.onSale) {
                    styleStatus = 'btn btn-process';
                  }

                  let txtFirst = '';
                  if (data.eventStatus === ini.onSale) {
                    if (data.paid !== ini.notPaid) {
                      txtFirst = ini.qrcode;
                    }
                  } else {
                    txtFirst = ini.endTH;
                  }

                  return (
                    <div className="ticket-row flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
                      <div className="ticket-type flex flex-column flex-lg-row align-items-lg-center">
                        <h3 className="entry-title">{data.eventName}</h3>
                        {/* <div className="ticket-price mt-3 mt-lg-0">
                          {data.eventZone}
                        </div> */}
                      </div>
                      <div className="div-date-history">
                        <div className="mt-2 mt-lg-0"> {data.qrCode}</div>
                      </div>

                      <div className="div-date-history">
                        <div className="mt-2 mt-lg-0"> {data.eventDate}</div>
                      </div>

                      <input
                        type="button"
                        name=""
                        value={txtFirst}
                        className={styleStatus}
                        onClick={
                          data.eventStatus === ini.onSale &&
                          data.paid !== ini.notPaid
                            ? () =>
                                this.handleClickQR(
                                  data.eventStatus,
                                  data.qrCode,
                                  data.eventName,
                                  data.bookingId
                                )
                            : () => {}
                        }
                      />

                      <input
                        type="button"
                        name=""
                        value={
                          data.eventStatus === ini.onSale &&
                          data.paid === ini.notPaid
                            ? ini.paidTH
                            : ''
                        }
                        className="btn btn-process"
                        onClick={
                          data.eventStatus === ini.onSale &&
                          data.paid === ini.notPaid
                            ? () =>
                                this.handleClickPaid(
                                  data.qrCode,
                                  data.eventName,
                                  data.bookingId
                                )
                            : () => {}
                        }
                      />

                      {data.eventStatus === ini.onSale &&
                      data.paid === ini.notPaid ? (
                        <Popconfirm
                          title={ini.confirmDelete}
                          onConfirm={
                            data.paid === ini.notPaid
                              ? () => this.confirm(data)
                              : () => {}
                          }
                          onCancel={() => {}}
                          okText="ตกลง"
                          cancelText="ยกเลิก"
                        >
                          <input
                            type="button"
                            name=""
                            value={
                              data.paid === ini.notPaid ? ini.cancelTH : ''
                            }
                            className="btn btn-process-paid"
                          />
                        </Popconfirm>
                      ) : (
                        <input
                          type="button"
                          name=""
                          value={
                            data.eventStatus === ini.onSale &&
                            data.paid === ini.notPaid
                              ? ini.cancelTH
                              : ''
                          }
                          className="btn btn-process-paid"
                        />
                      )}
                    </div>
                  );
                })}
              <Modal name="modalQR" overlayColor="rgba(255, 255, 255, 0.7)">
                <div className="ticket">
                  <div className="ticket-body">
                    <div className="head">
                      <span className="event">{eventName}</span>
                    </div>

                    <div className="contents-qrcode">
                      {qr && <center className="div-code-qr">{qr}</center>}
                      <div id="qrcodeOld" className="qrcode" />
                    </div>

                    <div className="footer">
                      <Button onClick={() => this.toggleCloseModal()}>
                        ปิด
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal name="modalPaid" overlayColor="rgba(255, 255, 255, 0.7)">
                <div className="ticket">
                  <div className="ticket-body">
                    <div className="head">
                      <span className="event">{eventName}</span>
                    </div>
                    <div className="contents-paid">
                      {qr}
                      <br />
                      <br />
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        headers={{
                          Authorization: ini.keyApp,
                        }}
                        data={{
                          bookingId,
                        }}
                        action={UPLOAD_SLIP}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="file"
                            style={{width: '100%'}}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      <br />
                    </div>
                    <div className="footer">
                      <div>
                        <Button onClick={() => this.handleClosePaid()}>
                          ปิด
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
