import * as ActionType from './actionType';
import * as service from '../service';
import * as ini from '../utils/InitialConfig';
import {getHistoryAll} from './actionMember';
// auiaoun test
// import * as dataEventAll from './mockup/eventAll20191201.json';
// import * as afterSaveBooking from './mockup/afterSaveBooking.json';

const setSeat = dataDB => {
  const dataRes = dataDB.result.map(dataAll => {
    const aa = dataAll.ticketlist.map(data => {
      let textResult = '';
      for (let countRow = 1; countRow <= data.row; countRow++) {
        for (let countColumn = 1; countColumn <= data.column; countColumn++) {
          if (countColumn == data.column) {
            textResult = `${`${textResult}${data.title}-${countRow}-${countColumn}`}`;
          } else {
            textResult = `${`${textResult}${data.title}-${countRow}-${countColumn}`},`;
          }
        }

        textResult += '|';
      }
      return {
        ...data,
        seat: textResult,
      };
    });

    return {
      ...dataAll,
      ticketlist: aa,
    };
  });

  console.log('dataRes', dataRes);
  return dataRes;
};

const getEventHome = callback => {
  return async dispatch => {
    try {
      const dataEvent = await service.callEventHome();
      if (dataEvent.length > 0) {
        dispatch({
          type: ActionType.GET_EVENT_HOME,
          data: dataEvent,
        });
        callback(ini.success, '');
      } else {
        callback(ini.error, ini.errorCallEventAll);
      }
    } catch (err) {
      console.log('getEventHome error', err);
      callback(ini.error, err);
    }
  };
};

const getEventAll = callback => {
  return async dispatch => {
    try {
      const dataEvent = await service.callEventAll();
      // const dataEvent = dataEventAll.default; // auiaoun test
      if (dataEvent.result && dataEvent.result.length > 0) {
        const dataRes = setSeat(dataEvent);
        dispatch({
          type: ActionType.GET_EVENT_ALL,
          data: dataRes,
        });
        callback(ini.success, dataEvent.result[0].eventId || '');
      } else {
        callback(ini.error, ini.errorCallEventAll);
      }
    } catch (err) {
      console.log('getEventAll error', err);
      callback(ini.error, err);
    }
  };
};

const saveBooking = (dataSave, callback) => {
  console.log('dataSave', dataSave);
  return async dispatch => {
    try {
      const dataRes = await service.saveBooking(dataSave);
      // const dataRes = afterSaveBooking.default; // auiaoun test
      console.log('dataRes', dataRes);
      if (dataRes.result === ini.Y) {
        dispatch({
          type: ActionType.AFTER_SAVE_BOOKING,
          // data: dataEventAll
        });
        callback(ini.success, '');
      } else {
        callback(ini.error, dataRes.errorMessage);
      }
    } catch (err) {
      console.log('saveBooking error', err);
    }
  };
};

const editBooking = (dataSave, callback) => {
  return async dispatch => {
    try {
      const dataRes = await service.editBooking(dataSave);
      // console.log('dataRes', dataRes);
      if (dataRes.result === ini.Y) {
        dispatch(
          getHistoryAll((status, err) => {
            callback(status, err);
          })
        );
      } else {
        callback(ini.error, dataRes.errorMessage);
      }
    } catch (err) {
      console.log('saveBooking error', err);
    }
  };
};

const getlistTalbleNotEmpty = (eventId, callback) => {
  // console.log('eventId', eventId);
  return async dispatch => {
    try {
      const dataTableNotEmpty = await service.getBookedTicket(eventId);
      if (dataTableNotEmpty.result) {
        const arrData = [
          dataTableNotEmpty.result.map(data => {
            return `${data.ticketTitle}${data.ticketRow}${data.ticketColumn}`;
          }),
        ];
        dispatch({
          type: ActionType.TABLE_NOT_EMPTY,
          data: arrData,
        });
        callback(ini.success, '');
      } else {
        callback(ini.error, ini.errorGetBookedTicketError);
      }
    } catch (err) {
      console.log('getlistTalbleNotEmpty error', err);
      callback(ini.error, err);
    }
  };
};

const getNews = callback => {
  return async dispatch => {
    try {
      const dataNews = await service.callNews();
      if (dataNews.length > 0) {
        dispatch({
          type: ActionType.GET_NEWS_HOME,
          data: dataNews,
        });
        callback(ini.success, '');
      } else {
        callback(ini.error, ini.errorCallEventAll);
      }
    } catch (err) {
      console.log('getNews error', err);
      callback(ini.error, err);
    }
  };
};

export {
  getEventHome,
  getEventAll,
  saveBooking,
  getlistTalbleNotEmpty,
  editBooking,
  setSeat,
  getNews,
};
