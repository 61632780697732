import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { message, Spin, Icon } from 'antd';
import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import { setDataloginFromSession } from '../actions/actionMember';
import * as ini from '../utils/InitialConfig';
import Header from '../components/main/Header';
import Content from '../components/news';
import Footer from '../components/main/Footer';
import { getNews } from '../actions/actionBuyTicket';

const News = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  const errors = (msg) => {
    message.error(msg);
  };

  useEffect(() => {
    props.dispatch(initApp());
    toggleLoading(true);
    props.dispatch(
      getNews((status, res) => {
        toggleLoading(false);
        if (status !== ini.success) {
          errors(res);
        }
      })
    );
  }, []); // eslint-disable-line

  const handleUpdateLoginBySession = () => {
    const header = window.mainHeader;
    props.dispatch(setDataloginFromSession(header));
  };

  const {
    member: { login },
    event: { newsData }
  } = props;

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );
  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <Header
          login={login}
          shopName={ini.shopName}
          setDataloginFromSession={handleUpdateLoginBySession}
        />
        <Content listData={newsData} />
        <Footer shopName={ini.shopName} txtFooter={ini.txtFooter} />{' '}
      </Spin>
    </div>
  );
};

News.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(News);
