import sessionstorage from 'sessionstorage';
import * as ActionType from './actionType';
import * as service from '../service';
import * as ini from '../utils/InitialConfig';
// auiaoun test
// import * as dataHistoryAll from './mockup/historyAll.json';
// import dataLogin from './mockup/dataLogin.json';

const getHistoryAll = callback => {
  return async (dispatch, getState) => {
    try {
      const stateMember = getState().member;

      let data;
      if (stateMember.login) {
        const dataHistory = await service.callHistory(stateMember.login.userid);
        // const dataHistory = dataHistoryAll.default; // auiaoun test
        // console.log('dataHistory', dataHistory);
        data = dataHistory.result;
        dispatch({
          type: ActionType.GET_MEMBER_HISTORY,
          data,
        });
        callback(ini.success, '');
      } else {
        callback(ini.error, ini.errorMsgDefault);
      }
    } catch (err) {
      console.log('getHistoryAll error', err);
      callback(ini.error, err);
    }
  };
};

const setNextPage = nextPage => {
  console.log('nextPage', nextPage);
  return async dispatch => {
    dispatch({
      type: ActionType.SET_NEXT_PAGE,
      nextPage,
    });
  };
};

const getLogin = (value, callback) => {
  return async dispatch => {
    try {
      const resLogin = await service.login(value);
      sessionstorage.setItem('userid', resLogin.userId);
      sessionstorage.setItem('fullname', resLogin.fullname);
      sessionstorage.setItem('username', resLogin.username);
      sessionstorage.setItem('email', resLogin.email || '');
      sessionstorage.setItem('phone', resLogin.phone || '');
      sessionstorage.setItem('chanelId', resLogin.chanelId || 0);
      sessionstorage.setItem('userBirthday', resLogin.userBirthday || '');

      if (resLogin.userId) {
        dispatch({
          type: ActionType.GET_MEMBER_LOGIN,
          data: {
            username: resLogin.username,
            userid: resLogin.userId,
            fullname: resLogin.fullname,
            email: resLogin.email,
            phone: resLogin.phone,
            chanelId: resLogin.chanelId,
            userBirthday: resLogin.userBirthday,
          },
        });
        // dispatch(getHistoryAll());
        /* login succrress go to home page */
        callback(ini.success, '');
      } else {
        callback(ini.error, ini.errorLoginUserPassInvalid);
      }
    } catch (err) {
      console.log('getLogin error', err);
      callback(ini.error, err);
    }
  };
};

const getRegister = (data, callback) => {
  console.log('getRegister', data);
  return async () => {
    try {
      const dataLogin = await service.register(data);
      if (!dataLogin.result) {
        callback(ini.error, ini.errorMsgDefault);
      } else if (dataLogin.result === ini.N) {
        if (dataLogin.errorMessage) {
          callback(ini.error, dataLogin.errorMessage);
        } else {
          callback(ini.error, ini.errorRegister);
        }
      } else {
        callback(ini.success, '');
      }
    } catch (err) {
      console.log('getRegister error', err);
      callback(ini.error, err);
    }
  };
};

const getUpdateUser = (data, callback) => {
  console.log('getUpdateUser', data);
  return async dispatch => {
    try {
      const dataUpdateUser = await service.updateUser(data);
      if (!dataUpdateUser.result) {
        callback(ini.error, ini.errorMsgDefault);
      } else if (dataUpdateUser.result === ini.N) {
        if (dataUpdateUser.errorMessage) {
          callback(ini.error, dataUpdateUser.errorMessage);
        } else {
          callback(ini.error, ini.errorRegister);
        }
      } else {
        sessionstorage.setItem('userid', data.userid);
        sessionstorage.setItem(
          'fullname',
          data.userFirstName + ' ' + data.userLastName
        );
        sessionstorage.setItem('username', data.username);
        sessionstorage.setItem('email', data.userEmail || '');
        sessionstorage.setItem('phone', data.userPhone || '');
        sessionstorage.setItem('userBirthday', data.userBirthday || '');

        dispatch({
          type: ActionType.GET_MEMBER_LOGIN,
          data: {
            username: data.username,
            userid: data.userid,
            fullname: data.userFirstName + ' ' + data.userLastName,
            email: data.userEmail,
            phone: data.userPhone,
            userBirthday: data.userBirthday,
            flagUpdate: true,
          },
        });
        callback(ini.success, '');
      }
    } catch (err) {
      console.log('getUpdateUser error', err);
      callback(ini.error, err);
    }
  };
};

const getForgotPassword = (userEmail, callback) => {
  console.log('getForgotPassword', userEmail);
  return async () => {
    try {
      const dataForgotPassword = await service.forgotPassword(userEmail);
      if (!dataForgotPassword.result) {
        callback(ini.error, ini.errorMsgDefault);
      } else if (dataForgotPassword.result === ini.N) {
        if (dataForgotPassword.errorMessage) {
          callback(ini.error, dataForgotPassword.errorMessage);
        } else {
          callback(ini.error, ini.errorForgotPassword);
        }
      } else {
        callback(ini.success, '');
      }
    } catch (err) {
      console.log('getForgotPassword error', err);
      callback(ini.error, err);
    }
  };
};

const getUpdatePassword = (data, callback) => {
  console.log('getUpdatePassword', data);
  return async () => {
    try {
      const dataUpdatePassword = await service.updatePassword(data);
      if (!dataUpdatePassword.result) {
        callback(ini.error, ini.errorMsgDefault);
      } else if (dataUpdatePassword.result === ini.N) {
        if (dataUpdatePassword.errorMessage) {
          callback(ini.error, dataUpdatePassword.errorMessage);
        } else {
          callback(ini.error, ini.errorUpdatePassword);
        }
      } else {
        callback(ini.success, '');
      }
    } catch (err) {
      console.log('getUpdatePassword error', err);
      callback(ini.error, err);
    }
  };
};

const setDataloginFromSession = data => {
  return async dispatch => {
    dispatch({
      type: ActionType.GET_MEMBER_LOGIN,
      data,
    });
  };
};

const logout = callback => {
  return async dispatch => {
    sessionstorage.setItem('userid', '');
    sessionstorage.setItem('fullname', '');
    sessionstorage.setItem('username', '');
    sessionstorage.setItem('email', '');
    sessionstorage.setItem('phone', '');
    sessionstorage.setItem('chanelId', '');
    sessionstorage.setItem('userBirthday', '');

    dispatch({
      type: ActionType.SET_MEMBER_LOGOUT,
    });
    callback();
  };
};

export {
  getHistoryAll,
  getLogin,
  setDataloginFromSession,
  logout,
  getRegister,
  getUpdateUser,
  setNextPage,
  getForgotPassword,
  getUpdatePassword,
};
