import React, { Component } from 'react';

import History from './History';

type Props = {
  memberReducer: Object,
  editBooking: Function,
  getDataHistory: Function
};

type State = { activeTab: string, activeEvent: Object };

export default class MemberIndex extends Component<Props, State> {
  render() {
    const {
      editBooking,
      getDataHistory,
      memberReducer: { login, historyAll }
    } = this.props;
    return (
      <div className="elements-page">
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="entry-header">
                  <h2 className="entry-title">สมาชิก</h2>

                  <ul className="breadcrumbs flex align-items-center">
                    <li>
                      <a href="#">หน้าแรก</a>
                    </li>
                    <li>สมาชิก</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {(historyAll && (
            <div className="main-content">
              <h2>{login.fullname}</h2>
              <a href="/#/updateuser">แก้ไขข้อมูลส่วนตัว</a> /  <a href="/#/updatepassword">เปลี่ยนรหัสผ่าน</a>
              <br /><br />
              <History
                historyAll={historyAll}
                editBooking={editBooking}
                getDataHistory={getDataHistory}
              />
            </div>
          )) || (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }
}
