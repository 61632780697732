import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { message, Spin, Icon } from 'antd';
import { connectRedux } from '../utils';
import { initApp } from '../actions/action';
import * as ini from '../utils/InitialConfig';
import Header from '../components/home/Header';
import Content from '../components/home/Content';
import Footer from '../components/main/Footer';
import { setDataloginFromSession, setNextPage } from '../actions/actionMember';
import { getEventHome, getNews } from '../actions/actionBuyTicket';

const Home = (props) => {
  const [state, setState] = useState(() => {
    const initialState = { showLoading: false };
    return initialState;
  });

  const toggleLoading = (status) => {
    setState({
      showLoading: status
    });
  };

  const errors = (msg) => {
    message.error(msg);
  };

  const checkNextPage = () => {
    const urlString = window.location.hash;
    let nextPage;
    if (urlString.indexOf('?page=') >= 0) {
      nextPage = urlString.replace('#/', '')
      nextPage = nextPage.replace('?page=', '')
    }
    return nextPage;
  };

  useEffect(() => {
    const nextPage = checkNextPage();
    if (nextPage) {
      props.dispatch(setNextPage(nextPage));
    }

    props.dispatch(initApp());
    toggleLoading(true);
    props.dispatch(
      getEventHome((status, res) => {
        toggleLoading(false);
        if (status !== ini.success) {
          errors(res);
        }
      })
    );
    toggleLoading(true);
    props.dispatch(
      getNews((status, res) => {
        toggleLoading(false);
        if (status !== ini.success) {
          errors(res);
        }
      })
    );
  }, []); // eslint-disable-line

  const handleUpdateLoginBySession = () => {
    const header = window.mainHeader;
    props.dispatch(setDataloginFromSession(header), () => { });
  };

  const {
    member: { login },
    event: { eventHome, newsData }
  } = props;

  const antIcon = (
    <Icon type="loading" style={{ fontSize: 50, color: '#000' }} spin />
  );
  return (
    <div>
      <Spin spinning={state.showLoading} indicator={antIcon}>
        <Header
          login={login}
          shopName={ini.shopName}
          setDataloginFromSession={handleUpdateLoginBySession}
        />
        <Content eventHome={eventHome} newsData={newsData} />
        <Footer shopName={ini.shopName} txtFooter={ini.txtFooter} />
      </Spin>
    </div>
  );
};

Home.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapState = state => state;

export default connectRedux(mapState)(Home);
