import React, { Component } from 'react';
import pic1 from '../../styles/images/news-image-1.jpg';
import pic2 from '../../styles/images/news-image-2.jpg';

type Props = { newsData: Object };

type State = {};

export default class Header extends Component<Props, State> {
  render() {
    const { newsData } = this.props;

    return (
      <div className="home-page-last-news">
        <div className="container">
          <div className="header">
            <div className="entry-title">
              <h2>ข่าวสาร</h2>
            </div>
          </div>
          <div className="home-page-last-news-wrap">
            <div className="row">
              {newsData
                && newsData.map((data, index) => {
                  if (index < 2) {
                    return (
                      <div className="col-12 col-md-6">
                        <figure className="featured-image">
                          <a href="/#/news">
                            <img
                              src={data.pathImage}
                              alt="fesival+celebration"
                            />
                          </a>
                        </figure>

                        <div className="box-link-date">
                          <a href="/#/news">{data.newsDate}</a>
                        </div>

                        <div className="content-wrapper">
                          <div className="entry-content">
                            <div className="entry-header">
                              <h2>
                                <a href="/#/news">{data.newsTitle}</a>
                              </h2>
                            </div>

                            <div className="entry-description">
                              <p>{data.newsDescription}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return <div />;
                })}
              {/* <div className="col-12 col-md-6">
                <figure className="featured-image">
                  <a href="#">
                    <img src={pic1} alt="fesival+celebration" />
                  </a>
                </figure>

                <div className="box-link-date">
                  <a href="#">03.12.18</a>
                </div>

                <div className="content-wrapper">
                  <div className="entry-content">
                    <div className="entry-header">
                      <h2>
                        <a href="#">10 Festival Tips</a>
                      </h2>
                    </div>

                    <div className="entry-meta">
                      <span className="author-name">
                        <a href="#"> By James Williams</a>
                      </span>
                      <span className="space">|</span>
                      <span className="comments-count">
                        <a href="#">3 comments</a>
                      </span>
                    </div>

                    <div className="entry-description">
                      <p>
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                        xfdfgdfgbfdgdfhdhdghhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
                        xcvfffffffffffffffffffffffffffffffffffffffffffffff
                        sssssssssssssssssssssssssssssssssssssssssssssssssssssss
                        ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                        xfdfgdfgbfdgdfhdhdghhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
                        xcvfffffffffffffffffffffffffffffffffffffffffffffff
                        sssssssssssssssssssssssssssssssssssssssssssssssssssssss
                        ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                        xfdfgdfgbfdgdfhdhdghhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
                        xcvfffffffffffffffffffffffffffffffffffffffffffffff
                        sssssssssssssssssssssssssssssssssssssssssssssssssssssss
                        ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <figure className="featured-image">
                  <a href="#">
                    <img src={pic2} alt="fesival+celebration" />
                  </a>
                </figure>

                <div className="box-link-date">
                  <a href="">03.12.18</a>
                </div>

                <div className="content-wrapper">
                  <div className="entry-content">
                    <div className="entry-header">
                      <h2>
                        <a href="#">New event calendar for this year</a>
                      </h2>
                    </div>

                    <div className="entry-meta">
                      <span className="author-name">
                        <a href="#">By James Williams</a>
                      </span>
                      <span className="space">|</span>
                      <span className="comments-count">
                        <a href="#">3 comments</a>
                      </span>
                    </div>

                    <div className="entry-description">
                      <p>
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                        Quisque at erat eu libero consequat tempus. Quisque mole
                        stie convallis tempus. Ut semper purus metus, a euismod
                        sapien sodales ac. Duis viverra eleifend fermentum.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
