/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Modal, Radio, /* DatePicker */ } from 'antd';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';
import * as ini from '../../utils/InitialConfig';


class Register extends Component {
  state = {
    isOpen: false,
    birthday: ''
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
  }

  handleSelect = (time) => {
    this.setState({ isOpen: false, birthday: moment(time).format('DD/MM/YYYY') });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.password.value !== e.target.confirmPassword.value) {
      this.alertError(ini.errorPassConfirmPassNotMatch);
      return;
    }

    if (!e.target.birthDay.value) {
      this.alertError(ini.errorInvalidBirthday);
      return;
    }

    if (e.target.phone.value.length !== 10) {
      this.alertError(ini.errorInvalidPhone);
      return;
    }

    const age = moment().diff(
      moment(e.target.birthDay.value, 'DD/MM/YYYY'),
      'years'
    );

    if (age < ini.ageRegister) {
      this.alertError(ini.errorAgeInvalid);
      return;
    }

    console.log('age', age);
    const { getRegister } = this.props;
    const data = {
      userId: '-1', // default
      activeFlag: '1', // default
      chanelId: '4', // default
      createBy: '1', // default
      deleteFlag: '0', // default
      updateBy: null, // default
      userRole: 'CUSTOMER', // default
      userThirdCode: '', // default
      username: e.target.username.value,
      userFirstName: e.target.firstName.value,
      userLastName: e.target.lastName.value,
      userPassword: window.btoa(e.target.password.value),
      userPhone: e.target.phone.value,
      userSex: e.target.sex.value,
      userEmail: e.target.mail.value,
      userBirthday: e.target.birthDay.value,
      userAge: age
    };
    getRegister(data);
  };

  alertError = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  disabledDate = (value) => {
    return value > new Date();
  };

  render() {
    const { goPageLogin, cancel } = this.props;
    const { birthday } = this.state;
    return (
      <div>
        <form className="register-form" onSubmit={this.handleSubmit}>
          <p className="login-text">สมัครสมาชิก</p>
          <input
            name="username"
            type="text"
            className="login-username"
            required="true"
            placeholder="ชื่อผู้ใช้"
          />
          <input
            name="password"
            type="password"
            className="login-password"
            required="true"
            placeholder="รหัสผ่าน"
          />
          <input
            name="confirmPassword"
            type="password"
            className="login-password"
            required="true"
            placeholder="ยืนยันรหัสผ่าน"
          />
          <input
            name="firstName"
            type="text"
            className="login-username"
            required="true"
            placeholder="ชื่อ"
          />
          <input
            name="lastName"
            type="text"
            className="login-username"
            required="true"
            placeholder="นามสกุล"
          />
          <input
            name="phone"
            type="text"
            pattern="[0-9]{10}"
            className="login-username"
            required="true"
            placeholder="เบอร์โทร"
          />
          <input
            name="mail"
            type="email"
            className="login-username"
            required="true"
            placeholder="เมลล์"
          />
          <div className="div-radio-sex">
            {/* <DatePicker
              name="birthDay"
              placeholder="วันเกิด"
              format="DD/MM/YYYY"
              required="true"
              style={{ width: '80%' }}
              disabledDate={this.disabledDate}
            /> */}
            
            <input
              name="birthDay"
              type="text"
              className="login-username"
              required="true"
              placeholder="วันเกิด"
              readOnly
              value={birthday}
              onClick={this.handleClick}
              style={{ width: '80%' }}
            />
            <DatePicker
              // value={birthday}
              isOpen={this.state.isOpen}
              onSelect={this.handleSelect}
              onCancel={this.handleCancel}
              // onChange={this.handleChange}
              theme="dark"
              confirmText="ตกลง"
              cancelText="ยกเลิก"
            />

            <Radio.Group
              name="sex"
              defaultValue="Male"
              buttonStyle="solid"
              size="small"
            >
              <Radio.Button value="Male">ชาย</Radio.Button>
              <Radio.Button value="Female">หญฺิง</Radio.Button>
            </Radio.Group>
          </div>
          <div>
            <input
              type="submit"
              name="Register"
              value="สมัครสมาชิก"
              className="login-submit"
            />
            {/* <input
              type="button"
              name="Login"
              value="เข้าสู่ระบบ"
              className="login-submit"
              onClick={() => goPageLogin()}
            /> */}
            <input
              type="button"
              name="cancel"
              value="ยกเลิก"
              className="login-submit"
              onClick={cancel}
            />

          </div>
        </form>
        <div className="underlay-photo" />
        <div className="underlay-black" />
      </div>
    );
  }
}
export default Register;
