import React, { Component } from 'react';
import { Result } from 'antd';

type Props = {};

type State = {};

export default class Zone extends Component<Props, State> {
  render() {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Back Home</Button>}
      />
    );
  }
}
