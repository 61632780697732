import React, { Component } from 'react';

type Props = { event: Object };

type State = {};

export default class Detail extends Component<Props, State> {
  render() {
    const { event } = this.props;

    // let eventData = {};
    // if (eventAll) {
    //   const filterData = eventAll.filter(data => data.eventId === 'e0006');
    //   eventData = filterData[0];
    // }

    return (
      <div>
        {event && (
          <div className="testimonial-wrap flex flex-wrap align-self-stretch">
            <div className="testimonials-container">
              <div className="swiper-container testimonials-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-content-wrap flex flex-wrap align-items-stretch">
                      <div className="testimonial-content">
                        <header className="entry-header">
                          <h2>{event.artist}</h2>
                          <h5>{event.eventTitle}</h5>
                        </header>

                        <div>
                          Start : {event.startDate}
                          <br />
                          End : {event.endDate}
                          <br />
                          Price : {event.price}
                          <br />
                        </div>
                        <br />
                        <div>{event.description}</div>
                        <br />
                        <footer>
                          <a href="#">{event.artist}, Buono Dine&Wine</a>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="testimonial-featured-image">
              <img src={event.eventImage} alt={event.artist} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
