/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Modal, Radio, /* DatePicker */ } from 'antd';
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment';
import * as ini from '../../utils/InitialConfig';


class UpdateUser extends Component {
  state = {
    isOpen: false,
    birthday: ''
  }
  
  componentDidMount = () => {
    const { memberReducer } = this.props;
    this.setState({
      birthday: memberReducer.login.userBirthday || ''
    });
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
  }

  handleSelect = (time) => {
    this.setState({ isOpen: false, birthday: moment(time).format('DD/MM/YYYY') });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!e.target.birthDay.value) {
      this.alertError(ini.errorInvalidBirthday);
      return;
    }

    if (e.target.phone.value.length !== 10) {
      this.alertError(ini.errorInvalidPhone);
      return;
    }

    const age = moment().diff(
      moment(e.target.birthDay.value, 'DD/MM/YYYY'),
      'years'
    );

    if (age < ini.ageRegister) {
      this.alertError(ini.errorAgeInvalid);
      return;
    }

    const { getUpdateUser, memberReducer } = this.props;
    const data = {
      userid: memberReducer.login.userid,
      username: e.target.username.value,
      userFirstName: e.target.firstName.value,
      userLastName: e.target.lastName.value,
      userPhone: e.target.phone.value,
      userSex: e.target.sex.value,
      userEmail: e.target.mail.value,
      userBirthday: e.target.birthDay.value,
      userAge: age
    };
    getUpdateUser(data);
  };

  alertError = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  disabledDate = (value) => {
    return value > new Date();
  };

  render() {
    const { cancel, goPageLogin, memberReducer } = this.props;
    const { birthday } = this.state;
    return (
      <div>
        <form className="register-form" onSubmit={this.handleSubmit}>
        {
          !memberReducer.login ?
          <div>
            <p className="forgot-password-text">
              กรุณาเข้าสู่ระบบ ก่อนทำการแก้ไขข้อมูล
            </p>
              <input
                type="button"
                name="cancel"
                value="กลับ"
                className="login-submit"
                onClick={goPageLogin}
              />
            </div>
            :
            <div>
              <p className="login-text">แก้ไขข้อมูล</p>
              <input
                name="username"
                type="text"
                className="login-username"
                required="true"
                placeholder="ชื่อผู้ใช้"
                value={memberReducer.login.username}
                readOnly
              />
              <input
                name="firstName"
                type="text"
                className="login-username"
                required="true"
                defaultValue={memberReducer.login.fullname.split(' ')[0] || ''}
                placeholder="ชื่อ"
              />
              <input
                name="lastName"
                type="text"
                className="login-username"
                required="true"
                defaultValue={memberReducer.login.fullname.split(' ')[1] || ''}
                placeholder="นามสกุล"
              />
              <input
                name="phone"
                type="text"
                pattern="[0-9]{10}"
                className="login-username"
                required="true"
                defaultValue={memberReducer.login.phone}
                placeholder="เบอร์โทร"
              />
              <input
                name="mail"
                type="email"
                className="login-username"
                required="true"
                defaultValue={memberReducer.login.email}
                placeholder="เมลล์"
              />
              <div className="div-radio-sex">
                <input
                  name="birthDay"
                  type="text"
                  className="login-username"
                  required="true"
                  placeholder="วันเกิด"
                  readOnly
                  value={birthday}
                  onClick={this.handleClick}
                  style={{ width: '80%' }}
                />
                <DatePicker
                  // value={birthday}
                  isOpen={this.state.isOpen}
                  onSelect={this.handleSelect}
                  onCancel={this.handleCancel}
                  // onChange={this.handleChange}
                  theme="dark"
                  confirmText="ตกลง"
                  cancelText="ยกเลิก"
                />

                <Radio.Group
                  name="sex"
                  defaultValue="Male"
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio.Button value="Male" >ชาย</Radio.Button>
                  <Radio.Button value="Female">หญฺิง</Radio.Button>
                </Radio.Group>
              </div>
              <div>
                <input
                  type="submit"
                  name="updateUser"
                  value="ตกลง"
                  className="login-submit"
                />
                <input
                  type="button"
                  name="cancel"
                  value="ยกเลิก"
                  className="login-submit"
                  onClick={cancel}
                />

              </div>
            </div>
          }
        </form>
        <div className="underlay-photo" />
        <div className="underlay-black" />
      </div>
    );
  }
}
export default UpdateUser;
