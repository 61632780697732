import React, { Component } from 'react';

import Menu from '../menu';

type Props = {
  shopName: String,
  setDataloginFromSession: Function,
  login: Object
};

type State = { classMenu: string };

export default class Header extends Component<Props, State> {
  state = { classMenu: 'site-navigation' };

  componentDidMount = () => {
    const { setDataloginFromSession } = this.props;
    const header = window.mainHeader;
    if (header.userid !== null) {
      setDataloginFromSession(header);
    }
  };

  toggleClassNameMenu = () => {
    const { classMenu } = this.state;
    this.setState({
      classMenu:
        classMenu === 'site-navigation show'
          ? 'site-navigation '
          : 'site-navigation show'
    });
  };

  render() {
    const { shopName, login } = this.props;
    const { classMenu } = this.state;
    return (
      <header className="site-header">
        <div className="header-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-10 col-lg-4">
                <h1 className="site-branding flex">
                  <a href="#">{shopName}</a>
                </h1>
              </div>

              <div className="col-2 col-lg-8">
                <nav
                  onClick={() => this.toggleClassNameMenu()}
                  className={classMenu}
                >
                  <div className="hamburger-menu d-lg-none">
                    <span />
                    <span />
                    <span />
                    <span />
                  </div>
                  <Menu login={login} />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
