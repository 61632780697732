/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Modal } from 'antd';
import * as ini from '../../utils/InitialConfig';

class UpdatePasswordIndex extends Component {
  state = {
    username: ''
  }

  getParameterByName = (name) => {
    const url = window.location.href;
    const tmpName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + tmpName + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount = () => {
    const code = this.getParameterByName('code');
    const paramUsername = this.getParameterByName('username');
    const loginUsername = !this.props.memberReducer.login ? '' : this.props.memberReducer.login.username;

    const username = paramUsername || loginUsername;
    this.setState({
      username
    });

    if (code && paramUsername) {
      if (ini.keyChangePass !== code) {
        this.setState({
          username: ''
        });
        this.alertError(ini.errorChangePassKeyNotValid);
      }
    }
  }

  alertError = (txt) => {
    Modal.error({
      title: ini.errorTitle,
      content: txt,
      centered: true
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (e.target.password.value !== e.target.confirmPassword.value) {
      this.alertError(ini.errorPassConfirmPassNotMatch);
      return;
    }

    const { save } = this.props;
    const data = {
      username: e.target.username.value,
      userPassword: window.btoa(e.target.password.value)
    };

    save(data);
  };

  render() {
    const { cancel } = this.props;
    const { username } = this.state;
    return (
      <div>
        <form className="login-form" onSubmit={this.handleSubmit}>
          {
            !username ?
              <div>
                <p className="forgot-password-text">
                กรุณาเข้าสู่ระบบ ก่อนทำการเปลี่ยนรหัสผ่าน
                </p>
                  <input
                    type="button"
                    name="cancel"
                    value="กลับ"
                    className="login-submit"
                    onClick={cancel}
                />
              </div>
               : 
              <div>
                  <p className="forgot-password-text">
                เปลี่ยนรหัสผ่าน
                </p>
                <input
                  name="username"
                  type="text"
                  className="login-username"
                  required="true"
                  placeholder="ชื่อผู้ใช้"
                  readOnly
                  value={username || ''}
                />
                <input
                  name="password"
                  type="password"
                  className="login-password"
                  required="true"
                  placeholder="รหัสผ่าน"
                />
                <input
                  name="confirmPassword"
                  type="password"
                  className="login-password"
                  required="true"
                  placeholder="ยืนยันรหัสผ่าน"
                />

                <div className="div-button-login">
                  <input
                    type="submit"
                    name="Login"
                    value="ตกลง"
                    className="login-submit"
                  />
                  <input
                    type="button"
                    name="cancel"
                    value="ยกเลิก"
                    className="login-submit"
                    onClick={cancel}
                  />
                </div>
              </div>
              
          } 
          
        </form>
        <div className="underlay-photo" />
        <div className="underlay-black" />
      </div>
    );
  }
}
export default UpdatePasswordIndex;
