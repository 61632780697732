import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ModalProvider } from './components/modal';

import Routers from './Routers';

const App = () => (
  <Router>
    <ModalProvider>
      <div className="wrap-page">
        <Routers />
      </div>
    </ModalProvider>
  </Router>
);

export default App;
