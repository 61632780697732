import { combineReducers } from 'redux';
import app from './app';
import event from './event';
import member from './member';

export default combineReducers({
  app,
  event,
  member
});
