import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import NoMatch from './pages/404';
import BuyTicket from './pages/BuyTicket';
import Contact from './pages/Contact';
import Member from './pages/Member';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import News from './pages/News';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import UpdateUser from './pages/UpdateUser';

export default () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/buyticket" component={BuyTicket} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/member" component={Member} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/news" component={News} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Route exact path="/updatepassword" component={UpdatePassword} />
      <Route exact path="/updateuser" component={UpdateUser} />
      <Route component={NoMatch} />
    </Switch>
  );
};
