const baseService = `${process.env.REACT_APP_SERVICE_URL}/api/v1`;

export const LOGIN = `${baseService}/user/login`;
export const REGISTER = `${baseService}/user/user`;
export const UPDATE_USER = `${baseService}/user/updateuser`;
export const FORGOT_PASSWORD = `${baseService}/user/forgetpassword`;
export const UPDATE_PASSWORD = `${baseService}/user/updatepassword`;
export const HISTORY = `${baseService}/user/history`;
export const SAVE_BOOKING = `${baseService}/user/booking`;
export const UPDATE_BOOKING = `${baseService}/user/updatebooking`;
export const UPLOAD_SLIP = `${baseService}/user/upload`;

export const EVENT_HOME = `${baseService}/home/eventhome`;
export const EVENT_ALL = `${baseService}/home/eventall`;
export const BOOKED_TICKET = `${baseService}/home/bookedticket`;

export const NEWS = `${baseService}/home/news`;
