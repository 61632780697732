import React, { Component } from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';

import moment from 'moment';

import Menu from '../menu';

type Props = {
  shopName: String,
  setDataloginFromSession: Function,
  login: Object
};

type State = { classMenu: string };

export default class Header extends Component<Props, State> {
  state = { classMenu: 'site-navigation' };

  componentDidMount = () => {
    const { setDataloginFromSession } = this.props;
    const header = window.mainHeader;
    if (header.userid !== null) {
      setDataloginFromSession(header);
    }
  };

  toggleClassNameMenu = () => {
    const { classMenu } = this.state;
    this.setState({
      classMenu:
        classMenu === 'site-navigation show'
          ? 'site-navigation '
          : 'site-navigation show'
    });
  };

  render() {
    const { shopName, login } = this.props;
    const { classMenu } = this.state;
    console.log('login', login);
    return (
      <div>
        <header className="site-header">
          <div className="header-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-10 col-lg-4">
                  <h1 className="site-branding flex">
                    <a href="#">{shopName}</a>
                  </h1>
                </div>

                <div className="col-2 col-lg-8">
                  <nav
                    onClick={() => this.toggleClassNameMenu()}
                    className={classMenu}
                  >
                    <div className="hamburger-menu d-lg-none">
                      <span />
                      <span />
                      <span />
                      <span />
                    </div>

                    <Menu login={login} />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="hero-content">
          <div className="container">
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-10">
                <div className="entry-header">
                  <h2>Welcome!</h2>

                  <div className="entry-meta-date">
                    {/* {moment(new Date()).format('DD.MM.YYYY')} */}
                  </div>
                </div>

                {!login && (
                  <div className="row">
                    <div className="col-12 ">
                      <div className="entry-footer">
                        <a href="/#/login" className="btn btn-home">
                          เข้าสู่ระบบ
                        </a>
                        <a href="/#/register" className="btn current btn-home">
                          สมัครสมาชิก
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Router>
              <div className="row">
                <div className="col-12 ">
                  <div className="entry-footer">
                    <a href="/buyticket" className="btn">
                      Buy Tickets
                    </a>
                    <a href="#" className="btn current">
                      See Lineup
                    </a>
                  </div>
                </div>
              </div>
            </Router> */}
          </div>
        </div>
      </div>
    );
  }
}
